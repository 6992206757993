import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Privacy from "../components/Modal/Privacy";
import Reset from "../components/Modal/Reset";
import Profile from "../components/Profile/Profile";
import WishList from "../components/WishList/WishList";
import LoaderMp4 from "../assets/img/loader.mp4";
import Dashboard from "../components/NewTemplate";

const Home = lazy(() => import("../container/Home"));
const LiveShows = lazy(() => import("../container/LiveShows"));
const Category = lazy(() => import("../container/Category"));
const Calender = lazy(() => import("../container/Calender"));
const LoginModal = lazy(() => import("../components/Modal/LoginModal"));
const NotificationModal = lazy(() =>
  import("../components/Modal/Notification")
);
const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="common-loader">
            <video loop="true" autoPlay={true} muted>
              <source src={LoaderMp4} type="video/webm" />
            </video>
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={(props) => <Home {...props} />} />
          <Route
            path="/liveshows"
            component={(props) => <LiveShows {...props} />}
          />

          <Route
            path="/category"
            component={(props) => (
              <Category {...props} key={props.location.key} />
            )}
          />
          <Route
            path="/calendar"
            component={(props) => <Calender {...props} />}
          />
          <Route
            path="/login"
            component={(props) => <LoginModal {...props} />}
          />
          <Route
            path="/notifyme"
            component={(props) => <NotificationModal {...props} />}
          />
          {/* <Route
            path="/video-interation/:id"
            component={(props) => <VideoInteractions {...props} />}
          /> */}
          <Route
            path="/video-interation/:id"
            component={(props) => <Dashboard {...props} />}
          />
          <Route
            path="/reset-password"
            component={(props) => <Reset {...props} />}
          />

          <Route
            path="/privacy"
            component={(props) => <Privacy {...props} />}
          />
          <Route
            path="/favorites"
            component={(props) => <WishList {...props} />}
          />
          <Route
            path="/profile"
            component={(props) => <Profile {...props} />}
          />
          <Route
            path="/newtemplate"
            component={(props) => <Dashboard {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
